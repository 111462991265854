import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AuthAction} from './auth.actions';
import {inject, Injectable} from '@angular/core';
import {AuthCredentials} from '../../api.graphql.g';
import {nanoid} from 'nanoid';
import {LoggerService} from '../logger.service';

export interface AuthStateModel extends AuthCredentials {
  clientId: string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    accessToken: null,
    refreshToken: null,
    clientId: null,
  }
})
@Injectable()
export class AuthState {
  private readonly logger = inject(LoggerService).create('Auth');

  @Selector()
  public static getState(state: AuthStateModel) {
    return state;
  }

  @Selector()
  public static clientId({clientId}: AuthStateModel) {
    return clientId;
  }

  @Selector()
  public static accessToken({accessToken}: AuthStateModel) {
    return accessToken;
  }

  @Selector()
  public static refreshToken({refreshToken}: AuthStateModel) {
    return refreshToken;
  }

  @Selector([AuthState.refreshToken])
  public static isAuthenticated(refreshToken: string) {
    return !!refreshToken;
  }

  @Action(AuthAction.Init)
  public init(ctx: StateContext<AuthStateModel>) {
    let {clientId} = ctx.getState();
    if (!clientId) {
      clientId = nanoid();
      ctx.patchState({clientId});
      this.logger.logInfo('Client id created');
    }
    this.logger.logInfo('Client id', {clientId});
  }

  @Action(AuthAction.Login)
  public login(ctx: StateContext<AuthStateModel>, {credentials}: AuthAction.Login) {
    ctx.patchState(credentials);
    this.logger.logInfo('Login');
  }

  @Action(AuthAction.Logout)
  public logout(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      accessToken: null,
      refreshToken: null,
    });
    this.logger.logInfo('Logout');
  }
}
